import { Link, useLocation, useNavigate } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { useCategories, useCategoriesTree } from "~/old-app/api/category";
import CategoriesDropdown from "~/old-app/components/Dropdowns/CategoriesDropdown";
import { useParamUpdater } from "~/old-app/hooks/useParamUpdator";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { cls } from "~/old-app/utils/helpers/cls";
import { Swiper } from "../Carousel/swiper";
import { QATAR_ISO_CODE } from "~/old-app/constants";
import { PiCarFill } from "react-icons/pi";

export default function CategoriesNavbar() {
  const {
    countries: countriesData,
    district,
    selectedService,
  } = useConfigSlice();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { accessToken } = useAuthSlice();
  const { categories } = useCategories({
    service_id: selectedService,
    is_tree: true,
    featured: 1,
    enabled: !accessToken || !selectedService,
    limit: 1000,
  });
  const { categories: categoriesTree, isLoading: treeLoading } =
    useCategoriesTree({
      service_id: selectedService,
      is_tree: true,
      enabled: !accessToken || !selectedService,
      limit: 1000,
    });

  const qatarCountry = countriesData?.find(
    (country) => country.iso2 === QATAR_ISO_CODE
  );

  const isQatar = qatarCountry?.id === district?.country_id;

  const { params } = useParamUpdater();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <div className="p-2 px-4 container flex gap-2">
      {isQatar && (
        <Link
          to={`/${language}/ask-for-car-item`}
          className="px-1 md:px-2 w-[150px] py-1 md:py-1 cursor-pointer text-sm flex gap-2 items-center text-primary-500 border border-primary-500 hover:bg-primary-500  hover:text-white active:border-white focus:ring-2 focus:ring-primary-50 focus:ring-opacity-20  rounded-lg disabled:text-gray-7000 disabled:border-gray-250 font-regular "
        >
          <PiCarFill />
          <span className="hidden md:flex w-fit">{t("car.fixCar")}</span>
        </Link>
      )}
      {categories?.length > 0 && (
        <CategoriesDropdown
          categories={categoriesTree}
          isLoading={treeLoading}
        />
      )}
      <Swiper
        items={categories}
        hideNavigation
        hidePagination
        hideNavigationButtons
        renderSlide={(item) => {
          const decodedPathName = decodeURIComponent(pathname);
          const isSame =
            decodedPathName.includes(`${item.slug}`) ||
            params?.["category_id"] === item.id + "";

          return (
            <Link
              to={`/${language}/categories/${item.slug}`}
              className={cls(
                "text-base font-medium text-gray-500 w-full flex items-center p-4 py-2  rounded-full",
                {
                  "bg-primary-500": isSame,
                }
              )}
            >
              <span
                onClick={() => {
                  navigate(`/${language}/categories/${item.slug || item.id}`);
                }}
                className={cls(
                  "line-clamp-1 w-full text-center text-gray-500 text-sm",
                  {
                    "text-white": isSame,
                  }
                )}
              >
                {item.name}
              </span>
            </Link>
          );
        }}
      />
    </div>
  );
}
