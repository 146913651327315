import { json, redirect } from "@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useNavigate } from
"@remix-run/react";
import { withSentry } from "@sentry/remix";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useChangeLanguage } from "remix-i18next/react";
import { DEFAULT_LANGUAGE, I18nSupportedLngs } from "~/i18n";
import AppProvider from "~/old-app/AppProvider";
import MainLayout from "~/old-app/layouts/MainLayout";
import { store } from "~/old-app/redux/index";
import { seoApis } from "./old-app/api/seo-apis";
import { Maintenance } from "./old-app/components/maintenance";
import { CookieEnum } from "./old-app/constants";
import { ModalProvider } from "./old-app/contexts/modal-context";
import { getCookie, setCookie } from "./old-app/utils/helpers/cookieManager";
import styles from "./tailwind.css?url";
import "react-day-picker/src/style.css?__remix_sideEffect__";

// to deny it from reload the component when change router
export const shouldRevalidate = ({
  actionResult,
  currentParams,
  currentUrl,
  defaultShouldRevalidate,
  formAction,
  formData,
  formEncType,
  formMethod,
  nextParams,
  nextUrl
}) => {
  return false;
};
export const links = () => [{ rel: "stylesheet", href: styles }];

export const handle = { i18n: "translation" };

export async function loader({ request }) {
  const pathname = new URL(request.url).pathname;

  const locale = pathname?.split("/")?.[1] || DEFAULT_LANGUAGE;

  if (!locale || !I18nSupportedLngs.includes(locale)) {
    throw redirect(`/${DEFAULT_LANGUAGE}${pathname}`, 302);
  }
  setCookie(CookieEnum.Language, locale);

  const remoteConfig = await seoApis.getRemoteConfig(request);

  return json({
    locale,
    remoteConfig,
    ENV: {
      NODE_ENV: process.env.NODE_ENV,
      BASE_BE_API: process.env.BASE_BE_API,
      BASE_BE_URL: process.env.BASE_BE_URL,
      FAVICON_HREF: process.env.FAVICON_HREF,
      APIKEY: process.env.APIKEY,
      APP_MODE: process.env.APP_MODE,
      SENTRY_DSN: process.env.SENTRY_DSN
    }
  });
}

// export const ErrorBoundary = () => {
//   const error = useRouteError();

//   captureRemixErrorBoundaryError(error);

//   return null;
// };

function Root() {
  const location = useLocation();
  const navigate = useNavigate();
  const { locale, ENV, remoteConfig } = useLoaderData();
  const [isReady, setIsReady] = useState(false);
  const {
    i18n: { changeLanguage }
  } = useTranslation();

  useChangeLanguage(locale);

  const [pass, setPass] = useState("");

  useEffect(() => {
    if (!isReady) {
      setPass(localStorage.getItem("_temp"));
      if (locale) {
        changeLanguage(locale);
        setCookie(CookieEnum.Language, locale);
        setIsReady(true);
      }
    }
  }, [locale]);

  const dir = locale === "ar" ? "rtl" : "ltr";

  useEffect(() => {
    if (ENV.APP_MODE === "production") {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "GTM-WVBWH8H");

      window.gtag = gtag;
    }
  }, [ENV.APP_MODE]);

  return (
    <html lang={locale} dir={dir}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />

        <meta name="theme-color" content="#000000" />
        <meta name="description" content="Hudhud shop" />
        <link rel="apple-touch-icon" href="/public/apple-touch-icon.png" />
        <meta
          name="facebook-domain-verification"
          content="cwy98sqsz78w0d3oetbg45xvctf4pi" />

        {!!remoteConfig?.fav_icon &&
        <link rel="shortcut icon" href={remoteConfig?.fav_icon} />}

        <title>{remoteConfig?.title}</title>
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(ENV)}`
          }} />


        {ENV.APP_MODE === "production" &&
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WVBWH8H');`
          }} />}



        {ENV.APP_MODE === "production" &&
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3120257,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
          }} />}



        {/* 
          hudhud prod
          WVBWH8H */}
        {/* 
          hudhud dev
          NRP4ZDKD */}
      </head>
      <body>
        <Provider store={store}>
          <AppProvider>
            <ModalProvider>
              <div id="root">
                {isReady && (
                !remoteConfig?.maintenance_mode?.is_active ||
                pass === "hudhud" ?
                <MainLayout>
                      <Outlet />
                    </MainLayout> :

                <Maintenance remoteConfig={remoteConfig} dir={dir} />)}

              </div>
            </ModalProvider>
          </AppProvider>
        </Provider>
        <ScrollRestoration />
        <ToastContainer className="z-[1000000]" />
        <Scripts />
        {ENV.APP_MODE === "production" &&
        <noscript>
            <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WVBWH8H"
            style={{
              display: "none",
              visibility: "hidden",
              height: 0,
              width: 0
            }}>
          </iframe>
          </noscript>}

        {process.env.NODE_ENV === "development" && <LiveReload />}
      </body>
    </html>);

}

export default withSentry(Root);