import Offers from "~/routes/offers";

const ServicesSplash = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto top-[100px]">
      <div className="flex flex-wrap items-center gap-2">
        <Offers
          showCloseButton
          onClose={onClose}
          onClickOffer={() => {
            onClose();
          }}
        />
      </div>
    </div>
  );
};

export default ServicesSplash;
