import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

export const CookieModal = ({ onAccept }) => {
  const { t } = useTranslation();
  return (
    <CookieConsent
      hideOnAccept
      acceptOnOverlayClick
      buttonText={t("global.acceptCookie")}
      disableStyles
      overlayClasses="bg-black bg-opacity-50"
      overlayStyle={{
        position: "fixed",
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 999,
        backgroundColor: "rgba(0, 0, 0, 0.3)",
      }}
      declineButtonClasses="hidden"
      buttonClasses="bg-primary-500 text-white px-4 py-2 rounded-md"
      containerClasses="flex justify-between items-center w-full fixed bottom-0 right-0 left-0 bg-primary-100 py-2 md:py-4 z-[10000000] px-2 md:px-4 flex-col md:flex-row gap-4"
      contentClasses="text-capitalize text-sm md:text-base"
      expires={9999999}
      overlay
      onAccept={() => {
        onAccept?.(true);
      }}
    >
      <div className="flex justify-between">{t("global.cookie")}</div>
    </CookieConsent>
  );
};
