import { Link } from "@remix-run/react";
import { useTranslation } from "react-i18next";
const Subcategory = ({ category }) => {
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <h2 className="text-sm font-medium whitespace-nowrap h-12">
      <Link to={`/${language}/categories/${category.slug}`}>
        {category.name}
      </Link>
    </h2>
  );
};

export default Subcategory;
