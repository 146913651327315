import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useConfigSlice } from "../redux/config-slice";
import { cls } from "../utils/helpers/cls";
import { generateWhatsappLink } from "../utils/helpers/renameFunc";

export const WhatsappSupport = ({ className }) => {
  const { remoteConfig } = useConfigSlice();

  const { pathname } = useLocation();

  const hideButton = useMemo(() => {
    return (
      pathname.includes("/products/") ||
      pathname.includes("/checkout") ||
      pathname.includes("/cart")
    );
  }, [pathname]);

  const goToWhatsapp = () => {
    window.open(
      generateWhatsappLink(remoteConfig?.whatsapp_number, ""),
      "_blank"
    );
  };
  return (
    !!remoteConfig?.whatsapp_number &&
    !hideButton && (
      <div
        className={cls(
          "fixed bottom-16 md:bottom-4  start-4 z-[999999999] ",
          className
        )}
      >
        <div
          className="flex items-center cursor-pointer justify-center gap-2"
          onClick={goToWhatsapp}
        >
          <div className="relative">
            <img
              src="/assets/whatsapp-chat.png"
              alt="whatsapp"
              className="w-12 h-12 rounded-full"
              loading="lazy"
            />
            <span className="absolute top-0 left-0 w-full h-full rounded-full border-4 border-primary-500 animate-ping "></span>
          </div>
          {/* <div className="bg-primary-500 px-4 py-2 rounded-md relative w-[20rem]">
            <span
              className={`text-sm text-white text-start ${
                document.dir === "rtl"
                  ? "after:content-[''] after:absolute after:top-1/2 after:left-full after:border-8 after:border-transparent after:border-l-primary-500 after:ml-[-1px]"
                  : "after:content-[''] after:absolute after:top-1/2 after:right-full after:border-8 after:border-transparent after:border-r-primary-500 after:mr-[-1px]"
              }`}
            >
              {t("account.askOnWhatsapp")}
            </span>
          </div> */}
        </div>
      </div>
    )
  );
};
