{
  "name": "hudhud",
  "version": "1.0.3",
  "description": "hudhud",
  "repository": "https://gitlab.com/troy-lab/matger-front",
  "license": "MIT",
  "type": "module",
  "dependencies": {
    "@fortawesome/fontawesome-free": "6.6.0",
    "@hookform/resolvers": "^3.9.1",
    "@material-ui/core": "^4.12.4",
    "@material-ui/icons": "^4.11.3",
    "@popperjs/core": "2.11.8",
    "@radix-ui/react-accordion": "^1.2.2",
    "@radix-ui/react-dialog": "^1.1.2",
    "@radix-ui/react-icons": "^1.3.2",
    "@radix-ui/react-popover": "^1.1.2",
    "@radix-ui/react-progress": "^1.1.0",
    "@radix-ui/react-radio-group": "^1.2.2",
    "@radix-ui/react-select": "^2.1.6",
    "@radix-ui/react-separator": "^1.1.0",
    "@radix-ui/react-slider": "^1.2.2",
    "@radix-ui/react-tooltip": "^1.1.8",
    "@reduxjs/toolkit": "^2.3.0",
    "@remix-run/node": "^2.11.1",
    "@remix-run/react": "^2.11.1",
    "@remix-run/serve": "^2.11.1",
    "@sentry/remix": "^8",
    "@tailwindcss/forms": "0.5.7",
    "@tanstack/react-query": "^5.52.1",
    "@u-wave/react-vimeo": "^0.9.8",
    "accept-language-parser": "^1.5.0",
    "axios": "^1.7.3",
    "axios-cache-interceptor": "^1.6.2",
    "chart.js": "4.4.3",
    "class-variance-authority": "^0.7.0",
    "classnames": "^2.3.1",
    "clsx": "^2.1.1",
    "cookie": "^0.6.0",
    "customize-cra": "^1.0.0",
    "date-fns": "^4.1.0",
    "dotenv": "^16.4.5",
    "dotenv-cli": "^7.4.2",
    "file-saver": "^2.0.5",
    "firebase": "^11.2.0",
    "formik": "^2.2.9",
    "glob": "^11.0.0",
    "google-maps-react": "^2.0.6",
    "gulp": "5.0.0",
    "gulp-append-prepend": "1.0.9",
    "html-react-parser": "^5.1.12",
    "i18next": "^23.14.0",
    "i18next-browser-languagedetector": "^8.0.0",
    "i18next-fetch-backend": "^6.0.0",
    "i18next-fs-backend": "^2.3.2",
    "i18next-http-backend": "^2.5.2",
    "immutable": "^4.1.0",
    "intl-parse-accept-language": "^1.0.0",
    "isbot": "^5.1.14",
    "jotai": "^2.10.1",
    "jquery": "^3.6.1",
    "js-cookie": "^3.0.5",
    "localforage": "^1.10.0",
    "localstorage-polyfill": "^1.0.1",
    "moment": "^2.29.4",
    "prop-types": "^15.8.1",
    "react": "^18.3.1",
    "react-app-rewired": "^2.2.1",
    "react-cache-buster": "^0.1.8",
    "react-circular-progressbar": "^2.1.0",
    "react-cookie-consent": "^9.0.0",
    "react-datepicker": "^8.1.0",
    "react-day-picker": "^9.5.1",
    "react-dom": "^18.3.1",
    "react-favicon": "^2.0.3",
    "react-gtm-module": "^2.0.11",
    "react-helmet": "^6.1.0",
    "react-hook-form": "^7.53.2",
    "react-i18next": "^15.0.1",
    "react-icons": "^5.4.0",
    "react-infinite-scroll-component": "^6.1.0",
    "react-input-mask": "^2.0.4",
    "react-intl": "^6.0.5",
    "react-lazyload": "^3.2.0",
    "react-loadingg": "^1.7.2",
    "react-movable": "^3.0.4",
    "react-phone-number-input": "^3.2.19",
    "react-query": "^3.39.3",
    "react-query-devtools": "^2.6.3",
    "react-rating": "^2.0.5",
    "react-rating-stars-component": "^2.2.0",
    "react-redux": "^9.1.2",
    "react-router-dom": "^6.26.0",
    "react-scripts": "5.0.1",
    "react-scroll": "^1.8.7",
    "react-select": "^5.8.0",
    "react-slick": "^0.30.2",
    "react-star-ratings": "^2.3.0",
    "react-swipeable": "^7.0.2",
    "react-toastify": "^10.0.5",
    "redux": "^5.0.1",
    "redux-devtools-extension": "^2.13.9",
    "redux-observable": "^3.0.0-rc.2",
    "redux-persist": "^6.0.0",
    "redux-persist-cookie-storage": "^1.0.0",
    "redux-persist-expire": "^1.1.1",
    "redux-persist-node-storage": "^2.0.0",
    "redux-thunk": "^3.1.0",
    "remix-custom-routes": "^1.0.1",
    "remix-i18next": "^6.3.0",
    "remix-utils": "^8.1.0",
    "rxjs": "^7.5.6",
    "sass": "^1.83.4",
    "scss": "^0.2.4",
    "slick-carousel": "^1.8.1",
    "swiper": "^8.0.5",
    "tailwind-merge": "^2.5.4",
    "use-breakpoint": "^4.0.1",
    "use-debounce": "^10.0.4",
    "use-dehydrated-state": "^0.1.0",
    "uuid": "^11.0.5",
    "yup": "^1.4.0"
  },
  "scripts": {
    "build": "yarn generate-meta-tag && remix build --sourcemap ",
    "build:sentry": "remix build --sourcemap &&  sentry-upload-sourcemaps --org hudhud-shop --project hudhudshop",
    "dev": "remix dev",
    "start": "NODE_OPTIONS='--import ./instrumentation.server.mjs' dotenv -e .env remix-serve build/index.js",
    "tsc": "tsc --noEmit",
    "generate-meta-tag": "node ./node_modules/react-cache-buster/dist/generate-meta-tag.js"
  },
  "devDependencies": {
    "@babel/eslint-parser": "^7.25.9",
    "@remix-run/dev": "^2.11.1",
    "@types/accept-language-parser": "^1.5.6",
    "@types/express": "^5.0.0",
    "@types/js-cookie": "^3.0.6",
    "@types/node": "^22.8.4",
    "@types/react": "^18.3.12",
    "@types/react-dom": "^18.3.1",
    "@types/react-gtm-module": "^2.0.4",
    "@types/react-input-mask": "^3.0.6",
    "@types/react-slick": "^0.23.13",
    "autoprefixer": "^10.4.1",
    "babel-eslint": "^10.1.0",
    "postcss": "^8.4.5",
    "tailwindcss": "^3.0.10",
    "tailwindcss-rtl": "^0.9.0"
  },
  "eslintConfig": {
    "extends": "react-app"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "optionalDependencies": {
    "typescript": "^5.6.3"
  }
}
