import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCategories, useCategoriesTree } from "~/old-app/api/category";
import {
  CartIcon,
  CategoriesIcon,
  HomeIcon,
  ServicesIcon,
  UserIcon,
} from "~/old-app/assets/icons";
import { useCartSlice } from "~/old-app/redux/cart-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import AccountSplash from "./AccountSplash";
import CategoriesSplash from "./CategoriesSplash";
import OtherSplash from "./OtherSplash";
import ServicesSplash from "./ServicesSplash";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { BiCategory, BiSolidOffer } from "react-icons/bi";

const MobileNavbar = ({}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();


  const { accessToken } = useAuthSlice();
  const isLoggedIn = !!accessToken;
  const {
    remoteConfig,
    isLoading: isRemoteConfigLoading,
    selectedService,
    serviceDetails,
    district_id,
  } = useConfigSlice();

  const isServiceDetailsLoading = !serviceDetails;
  const { cart, isLoading: isFetching } = useCartSlice();
  const { categories, isLoading } = useCategoriesTree({
    service_id: selectedService,
    is_tree: true,
    enabled: true,
    limit: 1000,
  });
  const isRootPath =
    location.pathname === "/" || /^\/[a-z]{2}(\/)?$/.test(location.pathname);

  const [showServices, setShowServices] = useState(false);
  const [showCategories, setShowCategories] = useState(false);
  const [showAccount, setShowAccount] = useState(false);
  const [showOther, setShowOther] = useState(false);

  return (
    <div className="fixed bottom-0 w-full bg-white border-t border-gray-200 z-50 md:hidden flex justify-around items-center p-2 h-[65px]">
      {showServices && (
        <ServicesSplash onClose={() => setShowServices(false)} />
      )}
      {showCategories && categories?.length > 0 && (
        <CategoriesSplash
          onClose={() => setShowCategories(false)}
          isLoading={isLoading}
          categories={categories}
        />
      )}
      {showAccount && <AccountSplash onClose={() => setShowAccount(false)} />}
      {showOther && (
        <OtherSplash onClose={() => setShowOther(false)} locale={language} />
      )}
      {!isRemoteConfigLoading &&
        remoteConfig?.enable_services_page &&
        !!district_id && (
          <button
            onClick={() => setShowServices(true)}
            className={`flex flex-col items-center ${
              showServices ? "text-primary-500" : "text-gray-500"
            }`}
          >
            <BiSolidOffer
              size={30}
              className={`${
                showServices ? "text-primary-500" : "text-gray-500"
              }`}
            />
            <span className="text-xs">{t("offers.title")}</span>
          </button>
        )}

      {!isServiceDetailsLoading &&
        serviceDetails?.show_categories_menu &&
        !!district_id && (
          <button
            onClick={() => setShowCategories(true)}
            className={`flex flex-col items-center ${
              showCategories ? "text-primary-500" : "text-gray-500"
            }`}
          >
            <BiCategory
              size={30}
              className={`${
                showCategories ? "text-primary-500" : "text-gray-500"
              }`}
            />
            <span className="text-xs">{t("global.categories")}</span>
          </button>
        )}
      {isLoggedIn ? (
        <>
          <Link
            to={`/${language}/cart`}
            className="flex flex-col items-center relative"
          >
            <div className="relative">
              <CartIcon size={30} className="text-gray-500" />
              {cart?.items_quantity > 0 && !isFetching && (
                <div className="absolute -top-2 start-5  bg-primary-500 text-white text-xs font-bold rounded-full h-6 w-6 flex items-center justify-center">
                  {cart?.items_quantity}
                </div>
              )}
            </div>
            <span className="text-xs">{t("global.cart")}</span>
          </Link>

          <button
            onClick={() => setShowAccount(true)}
            className={`flex flex-col items-center ${
              showAccount ? "text-primary-500" : "text-gray-500"
            }`}
          >
            <UserIcon
              size={30}
              className={`${
                showAccount ? "text-primary-500" : "text-gray-500"
              }`}
            />
            <span className="text-xs">{t("global.account")}</span>
          </button>
        </>
      ) : (
        <>
          <button
            onClick={() => navigate(`/${language}/signin`)}
            className="flex flex-col items-center"
          >
            <UserIcon size={30} className="text-gray-500" />
            <span className="text-xs">{t("global.login")}</span>
          </button>
        </>
      )}
    </div>
  );
};

export default MobileNavbar;
