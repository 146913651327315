import React, {useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import {useQuery} from "@tanstack/react-query";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {SearchIcon} from "~/old-app/assets/icons";
import client from "~/old-app/utils/plugins/ApiClient";
import { getCookie } from "~/old-app/utils/helpers/cookieManager";
import {useTranslation} from "react-i18next";
import { CookieEnum } from "~/old-app/constants";

const SearchForm = () => {
    let { t } = useTranslation();
    const navigate = useNavigate();
    const [showResults, setShowResults] = useState(false);
    const resultsRef = useRef(null);
    const [selectedService, setSelectedService] = useState(
      getCookie(CookieEnum.SelectedService)
    );

    const formik = useFormik({
        initialValues: {
            text: "",
        },
        onSubmit: (values) => {
            const trimmedText = values.text.trim();
            if (trimmedText) {
                navigate(`/search-results?page=1&query=${trimmedText}`);
            } else {
                toast.warn(t("global.fieldAdd"));
            }
        },
    });

    const {data} = useQuery({
            queryKey: ["searchData", formik.values.text],
            queryFn: async () => {
                const response = await client.get(`/services/search`, {
                    params: {
                        service_id: selectedService,
                        search: formik.values.text,
                    },
                });
                return response.data.response.data;
            },
            enabled: !!formik.values.text,
        }
    );
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (resultsRef.current && !resultsRef.current.contains(event.target)) {
                setShowResults(false);
            }
        };

        document.addEventListener("click", handleClickOutside);

        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);

    const handleKeyUp = (event) => {
        if (formik.values.text.trim() !== "") {
            setShowResults(true);
        }
    };

    return (
        <div className={`relative w-full my-2 lg:my-0 lg:w-5/12`}>
            <form onSubmit={formik.handleSubmit} className={`group flex relative `}>
                <input
                    type="text"
                    id="text"
                    name="text"
                    placeholder={t("global.search")}
                    className=" border text-sm pe-10 border-gray-100 text-gray-700 placeholder:text-gray-700 focus:ring-transparent w-full rounded-lg appearance-none disabled:text-gray-700/50 focus-within:border-primary-400"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyUp={handleKeyUp} // Show results when typing continues
                    value={formik.values.text}
                    onFocus={() => setShowResults(true)}
                    autoComplete="off" // Disable autocomplete
                />
                <button
                    type="submit"
                    className="rounded-e-md absolute top-0 end-0 p-2 pt-3 bottom-0 bg-primary-500  flex items-center group-focus-within:bg-primary-400 justify-center px-3 h-full  border-none text-white rounded-r rtl:rounded-l-lg rtl:rounded-r-none border border-gray-250 "
                >
                    <SearchIcon size={20} className="text-white "/>
                </button>
            </form>
            {data && data.length > 0 && showResults && (
                <div
                    ref={resultsRef}
                    className="w-full p-5 absolute z-50 bg-white rounded-b "
                >
                    {/* Render the search results */}
                    {Array.isArray(data) && data.length > 0 ? (
                        <ul className="flex flex-col gap-2">
                            {data.map((result) => (
                                <li key={result.id}>
                                    <a href={`/products/${result.id}`}>{result.name}</a>
                                </li>
                            ))}
                        </ul>
                    ) : null}
                </div>
            )}
        </div>
    );
};

export default SearchForm;
