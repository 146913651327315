import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useFavoritesNumber } from "~/old-app/api/favorite";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useCartSlice } from "~/old-app/redux/cart-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { cls } from "~/old-app/utils/helpers/cls";
import { useBreakpoint } from "~/old-app/utils/hooks/useBreakpoint";
import { CartIcon, FavIcon, SearchIcon } from "../../assets/icons";
import LangDropdown from "../Dropdowns/LangDropdown";
import UserOptionsDropdown from "../Dropdowns/UserOptionsDropdown";
import Search from "../Search/SearchForm";
import MobileNavbar from "./MobileNavbar";
import { CountrySelect } from "./country-select";
import CategoriesNavbar from "./CategoriesNavbar";
import { QATAR_ISO_CODE } from "~/old-app/constants";
import { PiCarFill } from "react-icons/pi";

export default function Navbar() {
  const { isMobile } = useBreakpoint();
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [searchOpen, setSearchOpen] = useState(false);
  const { accessToken, isInfluencer } = useAuthSlice();
  const {
    remoteConfig,
    serviceDetails,
    countries: countriesData,
    district,
  } = useConfigSlice();

  const qatarCountry = countriesData?.find(
    (country) => country.iso2 === QATAR_ISO_CODE
  );

  const isQatar = qatarCountry?.id === district?.country_id;
  const isLoggedIn = !!accessToken;
  const { cart, isLoading: isFetching } = useCartSlice();
  const navigate = useNavigate();

  const { total } = useFavoritesNumber({
    type: "products",
    limit: 1,
  });

  const renderCartIcon = (
    <li className="items-center flex">
      <Link
        to={`/${language}/cart`}
        className="inline-flex items-center relative"
      >
        <div className="relative">
          <CartIcon size={24} className="text-gray-400" />

          {!isFetching && cart?.items_quantity > 0 && (
            <div className="flex absolute -top-2 -right-1 justify-center items-center w-4 h-4 text-xxs font-bold text-white bg-primary-500 rounded-full">
              {cart?.items_quantity}
            </div>
          )}
        </div>
      </Link>
    </li>
  );

  return (
    <>
      <nav className="sticky top-0 w-full flex-col flex flex-wrap items-center justify-between py-3 navbar-expand-lg bg-white border-b border-gray-80 z-[99]">
        <div className="container flex flex-row items-center justify-between gap-4 mb-2 md:mb-0">
          <div className="flex flex-row gap-4 items-center justify-center w-fit">
            <Link to={`/${language}/`} className="flex items-center gap-2">
              <img
                alt={remoteConfig?.app_name}
                src={remoteConfig?.logo}
                loading="lazy"
                className="object-contain w-full h-10 md:w-[12.5rem] md:h-[4.125rem]"

                // className="object-contain mb-3 w-[60px] h-[20px]  md:w-[200px] md:h-[66px]"
                // className="max-h-10 min-h-10 min-w-10 object-cover mb-3 "
              />
            </Link>
            <div className="hidden lg:flex items-center gap-2">
              <CountrySelect />
            </div>
          </div>
          <div className="w-full hidden lg:flex lg:flex-1 items-center gap-2 bg-white lg:shadow-none mx-6 max-w-[40rem]">
            <Search />
          </div>

          <ul className="flex my-2 lg:my-0 gap-3 justify-center lg:justify-start list-none">
            {isQatar && (
              <li className="flex lg:hidden items-center cursor-pointer">
                <Link
                  to={`/${language}/ask-for-car-item`}
                  className="inline-flex items-center relative"
                >
                  <PiCarFill size={24} className="text-gray-400" />
                </Link>
              </li>
            )}
            <li className="flex lg:hidden items-center cursor-pointer">
              <div
                className="inline-flex items-center relative"
                onClick={() => setSearchOpen(!searchOpen)}
              >
                <SearchIcon size={24} className="text-gray-400" />
              </div>
            </li>

            {!isInfluencer && renderCartIcon}

            <li className="flex items-center cursor-pointer">
              <div
                onClick={() => {
                  if (isLoggedIn) {
                    navigate(`/${language}/account/my-favourites`);
                  } else {
                    navigate(`/${language}/signin`);
                  }
                }}
                className="inline-flex items-center relative"
              >
                <div className="relative">
                  <FavIcon size={24} className="text-gray-400" />

                  {total > 0 && (
                    <div className="flex absolute -top-2 -right-1 justify-center items-center w-4 h-4 text-xxs font-bold text-white bg-primary-500 rounded-full">
                      {total}
                    </div>
                  )}
                </div>
              </div>
            </li>

            <li className="flex items-center">
              <UserOptionsDropdown />
            </li>

            <li className="hidden lg:flex items-center">
              <LangDropdown />
            </li>
          </ul>
        </div>

        <div
          className={cls(
            "container flex-row items-center justify-between gap-4 flex lg:hidden animate-fade-in",
            searchOpen ? "flex" : "hidden"
          )}
        >
          <div className="w-full items-center gap-2 bg-white lg:shadow-none lg:mx-6">
            <Search />
          </div>
        </div>
        <div className="container flex-row items-center justify-between flex lg:hidden gap-4 animate-fade-in">
          <div className="flex w-full">
            <LangDropdown className="w-full" />
          </div>
          <div className="flex w-full">
            <CountrySelect className="w-full" />
          </div>
        </div>

        {!isMobile && serviceDetails?.show_categories_menu && (
          <CategoriesNavbar />
        )}
      </nav>

      {isMobile && <MobileNavbar className="animate-fade-in" />}
    </>
  );
}
