import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom"; // Replaced useHistory with useNavigate
import { IosArrowForward, LogoutIcon } from "~/old-app/assets/icons";
import { useTranslation } from "react-i18next";
import { useUserLogoutMutation } from "~/old-app/api/user";
import { AuthActions, useAuthSlice } from "~/old-app/redux/auth-slice";
import { asideLinks, asidePublicLinks } from "../Views/Account/Constants";
import { useConfig } from "~/old-app/hooks/use-config";

const AccountSplash = ({ onClose }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const User = useSelector((state) => state.User || {}, shallowEqual);
  const { accessToken, isInfluencer } = useAuthSlice();
  const isLoggedIn = !!accessToken;

  const checkActive = (path) => pathname.split("/").includes(path);

  const [userLogout] = useUserLogoutMutation();
  const logout = () => {
    // dispatch(authActions.logout({ pathname: pathname }));
    userLogout();
    dispatch(AuthActions.logout());
    // navigate("/");
  };
  const links = asideLinks();
  const { store_name } = useConfig();
  const publicLinks = asidePublicLinks(store_name);

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">{t("global.accountOptions")}</h2>
        <button
          onClick={onClose}
          className={`focus:outline-none focus:border-none hover:text-primary-500`}
        >
          <svg
            className={`fill-current text-black hover:text-primary-500`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex flex-col gap-2 overflow-y-auto">
        {isLoggedIn ? (
          links.map((group, index) => (
            <div className="mb-4" key={`group-${index}`}>
              {group.title && (
                <p className="mb-3 text-sm leading-2 text-gray-700 font-semibold">
                  {group.title}
                </p>
              )}
              {group.links.map((link, i) => (
                <Link
                  to={`/${language}/account/${link.path}`}
                  key={i}
                  onClick={onClose}
                  className={`flex items-center gap-3 px-4 py-3 rounded hover:bg-primary-60 ease-in-out duration-300 ${
                    checkActive(link.path) ? "bg-primary-60" : ""
                  }`}
                >
                  <div className="w-7 h-7 rounded bg-primary-400 flex justify-center items-center">
                    {link.icon}
                  </div>
                  <p className="grow text-sm leading-2 text-secondary-950 font-semibold">
                    {link.text}
                  </p>
                  <IosArrowForward
                    className="text-gray-700 rtl:rotate-180"
                    size={15}
                  />
                </Link>
              ))}
            </div>
          ))
        ) : (
          <></>
        )}
        {publicLinks.map((group, index) => (
          <div key={`group-${index}`}>
            {group.title && (
              <p className="mb-3 text-sm leading-2 text-gray-700 font-semibold">
                {group.title}
              </p>
            )}
            {group.links.map((link, i) => (
              <Link
                to={`/${language}/account/${link.path}`}
                key={i}
                onClick={onClose}
                className={`flex items-center gap-3 px-4 py-3 rounded hover:bg-primary-60 ease-in-out duration-300 ${
                  checkActive(link.path) ? "bg-primary-60" : ""
                }`}
              >
                <div className="w-7 h-7 rounded bg-primary-400 flex justify-center items-center">
                  {link.icon}
                </div>
                <p className="grow text-sm leading-2 text-secondary-950 font-semibold">
                  {link.text}
                </p>
                <IosArrowForward
                  className="text-gray-700 rtl:rotate-180"
                  size={15}
                />
              </Link>
            ))}
          </div>
        ))}
        <button
          onClick={logout}
          className={`flex items-center gap-3 px-4 py-3 rounded hover:bg-primary-60 ease-in-out duration-300`}
        >
          <div className="w-7 h-7 rounded bg-primary-400 flex justify-center items-center">
            <LogoutIcon className="text-white" size={16} />
          </div>
          <div className="text-sm leading-2 text-secondary-950 font-semibold">
            {t("global.logout")}
          </div>
        </button>
      </div>
    </div>
  );
};

export default AccountSplash;
