import { useTranslation } from "react-i18next";
import { useDistricts } from "~/old-app/api/country";
import { ConfigActions, useConfigSlice } from "~/old-app/redux/config-slice";
import { Select } from "../ui/custom-select";
import { dispatch } from "~/old-app/redux";
import { cls } from "~/old-app/utils/helpers/cls";

export const CountrySelect = ({ className }) => {
  const { t } = useTranslation();

  const { district_id } = useConfigSlice();
  const { districts, isLoading: districtsLoading } = useDistricts();

  const options = districts.map((district) => ({
    name: `${district.name} - ${district.currency_symbol}`,
    id: district.id,
  }));

  return (
    districts.length > 0 && (
      <Select
        preWord={t("navbars.country") + "-"}
        className={cls("w-[12rem]", className)}
        size="sm"
        onChange={(districtId) => {
          dispatch(ConfigActions.setDistrictId(districtId));
          setTimeout(() => {
            window.location.reload();
          });
        }}
        options={options}
        // options={districts}
        isLoading={districtsLoading}
        value={district_id}
        defaultValue={district_id}
        placeholder={t("navbars.country")}
        placeholderClassName="pe-2"
      />
    )
  );
};
