import { Link } from "@remix-run/react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSubscribeNewsLetterMutation } from "~/old-app/api/support";
import { useStaticPages } from "~/old-app/api/system-setting";
import { useSchemaForm } from "~/old-app/hooks/useSchemaForm";
import { useYup } from "~/old-app/hooks/useYup";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { generateWhatsappLink } from "~/old-app/utils/helpers/renameFunc";
import {
  AddressIcon,
  EmailIcon,
  Facebook,
  Instagram,
  Linkedin,
  MapIcon,
  PhoneIcon,
  Snapchat,
  Tiktok,
  Twitter,
  Whatsapp,
  Youtube,
} from "../../assets/icons";
import Button from "../Buttons/Button";
import Divider from "../Divider/Divider";
import { InputField } from "../ui/input-field";
import { getEnv } from "~/old-app/utils/helpers/getEnv";

export default function Footer({}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { accessToken } = useAuthSlice();
  const isLoggedIn = !!accessToken;
  const { remoteConfig } = useConfigSlice();
  const isRemoteConfigLoading = !remoteConfig;

  const [subscribe, { isLoading }] = useSubscribeNewsLetterMutation();
  const { Yup } = useYup();
  const schema = Yup.object({
    email: Yup.string().email().required(),
  });
  const defaultValues = {
    email: "",
  };

  const methods = useSchemaForm(schema, {
    defaultValues,
    reValidateMode: "onBlur",
    mode: "all",
  });
  const { handleSubmit, reset } = methods;

  const onSubscribe = async (data) => {
    await subscribe(data)
      .unwrap()
      .then(() => {
        toast.success(t("footer.subscribeSuccess"));
        reset(defaultValues);
      });
  };

  const { staticPages, isListLoading } = useStaticPages(
    { category: "footer" },
    { skip: isRemoteConfigLoading }
  );

  const settings = remoteConfig?.settings;

  const navigate = useNavigate();

  const openInfluencerSignInModal = () => {
    navigate(`/${language}/influencer-signin`);
  };

  return (
    <footer className="bg-footer md:text-start text-gray-700 py-5 text-center pb-16 md:pb-5">
      <div className="container pt-5">
        <div className="w-full lg:justify-between grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 mb-7">
          <div className="lg:mb-0 px-4 w-full col-span-2 md:grid-cols-3 lg:md:grid-cols-2  flex flex-col gap-4 items-start">
            <img
              alt={remoteConfig?.app_name}
              src={remoteConfig?.footer_logo}
              loading="lazy"
              className="w-32"
            />
            <p className="text-base">{remoteConfig?.footer?.about_us}</p>

            <FormProvider {...methods}>
              <div className="flex flex-col gap-2 items-start mt-6 md:mt-3 w-full mb-7 lg:mb-0">
                <div className="flex w-full">
                  <InputField
                    name="email"
                    type="email"
                    className="flex-grow bg-primary-100 text-gray-700 p-2 rounded-none rounded-s-md !rounded-e-none border-primary-500 placeholder:text-sm"
                    placeholder={t("auth.emailAddressPlaceholder")}
                    autoComplete="off"
                  />
                  <Button
                    className="bg-primary-500 text-white p-2 rounded-e-md px-3 rounded-s-none"
                    loading={isLoading}
                    onClick={handleSubmit(onSubscribe)}
                  >
                    {t("footer.subscribe")}
                  </Button>
                </div>
              </div>
            </FormProvider>
          </div>
          <div className="lg:mb-0 px-4 w-full  flex flex-col">
            <h3 className="text-lg text-primary-500 text-start">
              {t("footer.quickAccess")}
            </h3>
            <ul className="w-full flex flex-col list-unstyled gap-1 justify-start">
              {!isListLoading &&
                staticPages
                  ?.filter((item) => item.type === "about")
                  .map((item) => (
                    <li key={`footer-static-page${item.id}`}>
                      <Link
                        className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                        to={`/${language}/pages/${item.slug || item.id}`}
                        target="_blank"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}

              <li>
                <Link
                  className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                  to={`/${language}/offers`}
                >
                  {t("offers.title")}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                  to={`/${language}/blogs`}
                >
                  {t("blogs.title")}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                  to={`/${language}/faqs`}
                >
                  {t("faq.title")}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                  to={`/${language}/account/contact-us`}
                  target="_blank"
                >
                  {t("footer.contact")}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                  to={`/${language}/open-store-request`}
                >
                  {t("store.openStore")}
                </Link>
              </li>
              <li>
                <Link
                  className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold text-start"
                  to={`/${language}/send-influencer-request`}
                >
                  {t("influencer.becomeInfluencer")}
                </Link>
              </li>
            </ul>
          </div>
          <div className="lg:mb-0 px-4 w-full  flex flex-col text-start justify-start">
            <h3 className="text-lg text-primary-500">{t("footer.policies")}</h3>
            <ul className="list-unstyled gap-3 items-start">
              {!isLoggedIn && (
                <li>
                  <div
                    className="text-gray-700 cursor-pointer hover:text-gray-700 block pb-2 text-base font-regular text-start"
                    onClick={openInfluencerSignInModal}
                  >
                    {t("footer.influencerLogin")}
                  </div>
                </li>
              )}
              {!!window.ENV?.BASE_BE_URL && (
                <li>
                  <div
                    className="text-gray-700 cursor-pointer hover:text-gray-700 block pb-2 text-base font-regular text-start"
                    onClick={() => {
                      // open new tab
                      window.open(
                        `${window.ENV?.BASE_BE_URL}/admin/login`,
                        "_blank"
                      );
                    }}
                  >
                    {t("footer.sellerLogin")}
                  </div>
                </li>
              )}
              {!isListLoading &&
                staticPages
                  ?.filter((item) => item.type === "policy")
                  .map((item, index) => (
                    <li key={index}>
                      <Link
                        className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-regular"
                        to={`/${language}/pages/${item.slug || item.id}`}
                        target="_blank"
                      >
                        {item.title}
                      </Link>
                    </li>
                  ))}
            </ul>
          </div>
          <div className="lg:mb-0 px-4 w-full  flex flex-col  text-start">
            <h3 className="text-lg text-primary-500">
              {t("footer.contactInfo")}
            </h3>
            {settings?.general_setting && (
              <ul className="list-unstyled flex flex-col gap-1 items-start">
                <li>
                  <a
                    className="flex gap-2 items-center text-gray-700 hover:text-gray-700 pb-2 text-base "
                    href={`mailto:${settings?.general_setting.email}`}
                    target="_blank"
                  >
                    <EmailIcon /> {settings?.general_setting.email}
                  </a>
                </li>
                <li>
                  <p
                    className="flex gap-2 items-start text-gray-700  pb-2 text-base cursor-pointer"
                    onClick={() => {
                      window.location.href = `tel:${settings?.general_setting.phone_number}`;
                    }}
                  >
                    <PhoneIcon className="mt-1" />
                    <span dir="ltr">
                      {settings?.general_setting.phone_number}
                    </span>
                  </p>
                </li>
                <li>
                  <p className="flex gap-2 items-start text-gray-700  pb-2 text-base ">
                    <AddressIcon className="shrink-0 mt-1" />
                    {language === "ar"
                      ? settings?.general_setting.address_ar
                      : settings?.general_setting.address_en}
                  </p>
                </li>
                {settings?.general_setting.map_url && (
                  <li>
                    <a
                      className="flex gap-2 items-center text-gray-700 hover:text-gray-700 pb-2 text-base "
                      href={settings?.general_setting.map_url}
                      target="_blank"
                    >
                      <MapIcon /> {t("footer.findUs")}
                    </a>
                  </li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="px-4">
          <Divider borderColor="gray-400" />
        </div>
        <div className="w-full flex flex-wrap items-center">
          <div className="flex flex-col md:flex-row items-center justify-between w-full mb-4 md:mb-0">
            <div className="flex-1 w-full flex flex-col justify-center md:justify-start">
              {(settings?.general_setting?.ios_app_link ||
                settings?.general_setting?.android_app_link) && (
                <div className="mb-7 lg:mb-0 px-4 w-full md:w-auto flex flex-col items-center md:items-start ">
                  <h3 className="text-lg text-primary-500">
                    {t("footer.getAppTitle")}
                  </h3>
                  <ul className="flex items-center flex-row  justify-center gap-2">
                    {settings?.general_setting?.ios_app_link && (
                      <li>
                        <a
                          className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold "
                          href={settings?.general_setting?.ios_app_link}
                        >
                          <img
                            alt="app store Logo"
                            src={"/defaults/apple-store.svg"}
                            className="h-12 object-contain cursor-pointer"
                            loading="lazy"
                          />
                        </a>
                      </li>
                    )}
                    {settings?.general_setting?.android_app_link && (
                      <li>
                        <a
                          className="text-gray-700 hover:text-gray-700 block pb-2 text-base font-semibold"
                          href={settings?.general_setting?.android_app_link}
                        >
                          <img
                            alt="play store Logo"
                            src={"/defaults/google-store.svg"}
                            className="h-12 object-contain cursor-pointer"
                            loading="lazy"
                          />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>

            <div className="flex flex-row  gap-5 items-center justify-end mt-3 flex-1 ">
              <img
                src="/assets/etbis-logo.jpeg"
                className="h-14 w-fit  rounded-md object-contain"
                loading="lazy"
              />
              <img
                src="/assets/blue-logo.svg"
                className="h-12 w-fit  rounded-md object-contain"
                loading="lazy"
              />
              <img
                src="/assets/e_sirket.png"
                className="h-14 w-fit  rounded-md object-contain"
                loading="lazy"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 px-4  text-base font-semibold  items-center justify-between w-full">
            <div className="flex flex-row gap-2 items-center   w-full">
              {!!settings?.social_media && (
                <div className=" mt-3 md:mt-0 w-full  flex gap-3 justify-center md:justify-start ">
                  <>
                    {!!settings?.social_media?.Twitter && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.Twitter}
                        target="_blank"
                      >
                        <Twitter size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.Instagram && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.Instagram}
                        target="_blank"
                      >
                        <Instagram size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.Facebook && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.Facebook}
                        target="_blank"
                      >
                        <Facebook size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.Linkedin && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.Linkedin}
                        target="_blank"
                      >
                        <Linkedin size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.Youtube && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.Youtube}
                        target="_blank"
                      >
                        <Youtube size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.TikTok && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.TikTok}
                        target="_blank"
                      >
                        <Tiktok size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.Snapchat && (
                      <Link
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex  "
                        to={settings?.social_media?.Snapchat}
                        target="_blank"
                      >
                        <Snapchat size={20} className="text-white" />
                      </Link>
                    )}
                    {!!settings?.social_media?.Whatsapp && (
                      <a
                        className="text-white items-center justify-center align-center rounded-full outline-none focus:outline-none bg-gray-600 w-10 h-10 hover:bg-primary-500  flex cursor-pointer "
                        href={generateWhatsappLink(
                          settings?.social_media?.Whatsapp,
                          "%20"
                        )}
                        target="_blank"
                      >
                        <Whatsapp size={20} className="text-white" />
                      </a>
                    )}
                  </>
                </div>
              )}
            </div>
            <span className="flex items-center justify-center md:justify-end w-full text-gray-400 my-6 md:my-0">
              <div className="flex flex-col gap-2 items-end">
                <img
                  src="/assets/payment2.png"
                  className="h-20 w-full  rounded-md object-contain "
                  loading="lazy"
                />
                {remoteConfig?.footer?.rights} © {new Date().getFullYear()}
              </div>
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
}
