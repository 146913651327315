import React, { useState, useRef, useEffect, Fragment, useMemo } from "react";
import { createPopper } from "@popperjs/core";
import { Link, useNavigate } from "react-router-dom";
import { ListIcon } from "~/old-app/assets/icons";
import Subcategory from "~/old-app/components/Navbars/Subcategory";
import NoData from "~/old-app/components/Cards/NoData";
import Spinner from "~/old-app/components/Spinner";
import listenForOutsideClicks from "~/old-app/utils/helpers/listen-for-outside-clicks";
import { useTranslation } from "react-i18next";
import { DefaultImage } from "~/old-app/constants";
import { cls } from "~/old-app/utils/helpers/cls";

const CategoriesDropdown = ({ categories, isLoading }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isMobileSplashOpen, setIsMobileSplashOpen] = useState(false);
  const btnDropdownRef = useRef(null);
  const popoverDropdownRef = useRef(null);
  const navigate = useNavigate();

  const { addEventListeners, removeEventListeners } = listenForOutsideClicks(
    setDropdownPopoverShow,
    popoverDropdownRef,
    btnDropdownRef
  );

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  // Function to open the dropdown
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
      modifiers: [
        {
          name: "eventListeners",
          options: {
            scroll: true,
            resize: true,
          },
        },
      ],
    });
    setDropdownPopoverShow(true);
  };

  useEffect(() => {
    if (dropdownPopoverShow) {
      createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
        placement: "bottom-start",
        modifiers: [
          {
            name: "eventListeners",
            options: {
              scroll: true,
              resize: true,
            },
          },
        ],
      });
    }
  }, [dropdownPopoverShow]);

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
    setSelectedCategory(null);
  };

  const handleCategoryClick = (item) => {
    navigate(`/${language}/categories/${item.slug || item.id}`);
    closeDropdownPopover();
  };

  useEffect(() => {
    if (dropdownPopoverShow) {
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [dropdownPopoverShow, addEventListeners, removeEventListeners]);

  const subCategories = useMemo(() => {
    if (!selectedCategory) return [];
    const subCats = [];

    selectedCategory?.children?.forEach((subCat) => {
      subCats.push({ ...subCat, parent: 1 });
      subCat?.children?.forEach((subSubCat) => {
        subCats.push({
          ...subSubCat,
          parent: 2,
        });
      });
    });

    return subCats;
  }, [selectedCategory]);

  return (
    <>
      <div
        ref={btnDropdownRef}
        className="font-bold rtl:font-bold text-gray-700  items-center gap-2 text-sm flex cursor-pointer"
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <ListIcon />{" "}
        <span className="" style={{ width: "max-content" }}>
          {t("filter.allCategories")}
        </span>
      </div>

      <div
        ref={popoverDropdownRef}
        className={`${
          dropdownPopoverShow ? "flex" : "hidden"
        } justify-center gap-8 bg-white text-base w-full z-30 absolute top-0 left-0 list-none text-start rounded shadow-xl min-w-[10rem]`}
      >
        <div className="absolute left-0 w-full bg-white shadow-lg p-5">
          <div className="flex">
            {isLoading ? (
              <div className="w-full items-center justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <div className="w-1/4 max-h-[70vh] overflow-y-scroll py-4 ps-4 min-w-[25%]">
                  {categories?.map((category) => (
                    <div
                      key={category.id}
                      onMouseEnter={() => handleCategorySelect(category)}
                      className="relative"
                    >
                      <button
                        className={`w-full flex items-center gap-2 py-2 px-4 text-left ${
                          selectedCategory &&
                          selectedCategory.id === category.id
                            ? "bg-gray-200"
                            : ""
                        }`}
                        onClick={() => handleCategoryClick(category)}
                      >
                        <div className="border-e border-gray-300 pe-5 line-clamp-1">
                          <img
                            src={category.cover || DefaultImage}
                            className="w-10 h-10 object-contain flex-shrink-0"
                            alt={category.name}
                            loading="lazy"
                            onError={(e) => {
                              e.target.src = DefaultImage;
                            }}
                          />
                        </div>
                        {category.name}
                      </button>
                    </div>
                  ))}
                </div>
                <div
                  className={cls(" px-12 py-2 w-[25%] h-[70vh] flex ", {
                    "w-full items-center justify-center":
                      !selectedCategory?.children ||
                      !selectedCategory?.children?.length,
                  })}
                  // width: 300px;
                  // height: 70vh;
                  // display: flex;

                  onMouseEnter={() => {
                    if (selectedCategory) {
                      handleCategorySelect(selectedCategory);
                    }
                  }}
                >
                  <div className="flex flex-col flex-wrap gap-2 w-full">
                    {selectedCategory && subCategories?.length > 0 ? (
                      subCategories?.map((subcategory) => (
                        <div
                          key={`sub-cat-${subcategory.id}`}
                          // className="w-1/4"
                          className=""
                        >
                          <span
                            className={cls("text-sm  whitespace-nowrap  ", {
                              "text-primary-500 font-semibold ":
                                subcategory.parent === 1,
                            })}
                            onClick={closeDropdownPopover}
                          >
                            <Link
                              to={`/${language}/categories/${subcategory.slug}`}
                            >
                              {subcategory.name}
                            </Link>
                          </span>

                          {/* {subcategory?.children.map((subSubcategory) => (
                            <span
                              className="text-sm font-medium whitespace-nowrap "
                              key={`sub-sub-cat-${subSubcategory.id}`}
                              onClick={closeDropdownPopover}
                            >
                              <Link
                                to={`/${language}/categories/${subSubcategory.slug}`}
                              >
                                {subSubcategory.name}
                              </Link>
                            </span>
                          ))} */}
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center justify-center">
                        <NoData subtitle={t("filter.noSubcategories")} />
                      </div>
                    )}
                    {/* {selectedCategory &&
                    selectedCategory.children.length > 0 ? (
                      selectedCategory?.children.map((subcategory) => (
                        <div
                          key={`sub-cat-${subcategory.id}`}
                          className="flex flex-col gap-1"
                        >
                          <span
                            className="text-sm font-semibold font-medium whitespace-nowrap  text-primary-500"
                            onClick={closeDropdownPopover}
                          >
                            <Link
                              to={`/${language}/categories/${subcategory.slug}`}
                            >
                              {subcategory.name}
                            </Link>
                          </span>

                          {subcategory?.children.map((subSubcategory) => (
                            <span
                              className="text-sm font-medium whitespace-nowrap "
                              key={`sub-sub-cat-${subSubcategory.id}`}
                              onClick={closeDropdownPopover}
                            >
                              <Link
                                to={`/${language}/categories/${subSubcategory.slug}`}
                              >
                                {subSubcategory.name}
                              </Link>
                            </span>
                          ))}
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center justify-center">
                        <NoData subtitle={t("filter.noSubcategories")} />
                      </div>
                    )} */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {isMobileSplashOpen && (
        <div className="fixed inset-0 bg-white z-40 overflow-auto">
          {/* Mobile view implementation remains the same */}
        </div>
      )}
    </>
  );

  // return (
  //   <div className="category-modal">
  //     <Popover
  //       open={isOpened}
  //       onOpenChange={(e) => {
  //         if (disabled) return;
  //         setIsOpened(e);
  //       }}
  //     >
  //       <PopoverTrigger asChild>
  //         <button
  //           ref={btnDropdownRef}
  //           className="font-bold rtl:font-bold text-gray-700 flex items-center gap-2 text-sm"
  //           onClick={(e) => {
  //             e.preventDefault();
  //             setIsOpened(!isOpened);
  //             // dropdownPopoverShow
  //             //   ? closeDropdownPopover()
  //             //   : openDropdownPopover();
  //           }}
  //         >
  //           <ListIcon /> {t("filter.allCategories")}
  //         </button>
  //       </PopoverTrigger>
  //       <PopoverContent
  //         className="h-[80vh] w-[80vh] shadow-lg rounded-md bg-white "
  //         align="center"
  //         // style={style}
  //       >
  //         <div
  //           className={`${
  //             dropdownPopoverShow ? "flex" : "hidden"
  //           } justify-center gap-8 bg-white text-base w-full z-30 absolute top-0 left-0 list-none text-start rounded shadow-xl min-w-[10rem]`}
  //         >
  //           <div className="absolute left-0 w-full bg-white shadow-lg p-5">
  //             <div className="flex">
  //               {isLoading ? (
  //                 <div className="w-full items-center justify-center">
  //                   <Spinner />
  //                 </div>
  //               ) : (
  //                 <>
  //                   <div className="w-1/4 max-h-[70vh] overflow-y-scroll py-4 ps-4">
  //                     {categories?.map((category) => (
  //                       <div
  //                         key={category.id}
  //                         onMouseEnter={() => handleCategorySelect(category)}
  //                         className="relative"
  //                       >
  //                         <button
  //                           className={`w-full flex items-center gap-2 py-2 px-4 text-left ${
  //                             selectedCategory &&
  //                             selectedCategory.id === category.id
  //                               ? "bg-gray-200"
  //                               : ""
  //                           }`}
  //                           onClick={() => handleCategoryClick(category.slug)}
  //                         >
  //                           <div className="border-e border-gray-300 pe-5">
  //                             <img
  //                               src={category.icon || DefaultImage}
  //                               className="w-10 h-10 object-contain flex-shrink-0"
  //                               alt={category.name}
  //                               onError={(e) => {
  //                                 e.target.src = DefaultImage;
  //                               }}
  //                             />
  //                           </div>
  //                           {category.name?.substring(0, 20)}{" "}
  //                           {category.name?.length > 20 && ".."}
  //                         </button>
  //                       </div>
  //                     ))}
  //                   </div>
  //                   <div
  //                     className="w-3/4 px-12 py-2"
  //                     onMouseEnter={() => {
  //                       if (selectedCategory) {
  //                         handleCategorySelect(selectedCategory);
  //                       }
  //                     }}
  //                   >
  //                     <div className="flex flex-wrap gap-4 md:gap-8">
  //                       {selectedCategory &&
  //                       selectedCategory.children.length > 0 ? (
  //                         selectedCategory?.children.map((subcategory) => (
  //                           <Subcategory
  //                             key={`sub-cat-${subcategory.id}`}
  //                             category={subcategory}
  //                           />
  //                         ))
  //                       ) : (
  //                         <NoData subtitle={t("filter.noSubcategories")} />
  //                       )}
  //                     </div>
  //                   </div>
  //                 </>
  //               )}
  //             </div>
  //           </div>
  //         </div>
  //       </PopoverContent>
  //     </Popover>

  //     {isMobileSplashOpen && (
  //       <div className="fixed inset-0 bg-white z-40 overflow-auto">
  //         {/* Mobile view implementation remains the same */}
  //       </div>
  //     )}
  //   </div>
  // );
};

export default CategoriesDropdown;
