import { cloneElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useInfluencerLogoutMutation } from "~/old-app/api/influencer";
import { useUserLogoutMutation } from "~/old-app/api/user";
import {
  LocationOn,
  LogoutIcon,
  OrdersIcon,
  UserProfileIcon,
  UserSolidIcon,
} from "~/old-app/assets/icons";
import { DefaultImage } from "~/old-app/constants";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { cls } from "~/old-app/utils/helpers/cls";
import { asideLinks } from "../Views/Account/Constants";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Separator } from "../ui/separator";
import { useConfigSlice } from "~/old-app/redux/config-slice";

const UserOptionsDropdown = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const { user, isInfluencer, accessToken } = useAuthSlice();
  const isLoggedIn = !!accessToken;
  const [logout, { isLoading }] = useUserLogoutMutation();
  const [influencerLogout, { isLoading: influencerLoading }] =
    useInfluencerLogoutMutation();
  const links = asideLinks();
  const [isOpened, setIsOpened] = useState(false);
  const { remoteConfig } = useConfigSlice();

  return (
    <div className="inline-flex items-center relative">
      <Popover
        open={isOpened}
        onOpenChange={(e) => {
          setIsOpened(e);
        }}
      >
        <PopoverTrigger asChild>
          <div
            className="flex items-center gap-4 cursor-pointer justify-normal"
            onClick={() => {
              if (!isLoggedIn) {
                navigate(`/${language}/signin`);
                return;
              }
              setIsOpened(!isOpened);
            }}
          >
            <UserProfileIcon
              size={22}
              className={cls("text-gray-400", {
                "text-primary-500": isLoggedIn,
              })}
            />
          </div>
        </PopoverTrigger>

        <PopoverContent
          className="h-auto max-h-[20rem]  bg-main shadow-lg rounded-md bg-white"
          align="center"
        >
          {isLoggedIn && (
            <div className={cls(" w-full relative flex flex-col")}>
              {isLoggedIn && (
                <>
                  <div
                    className="flex flex-row items-center justify-between   w-full cursor-pointer  hover:text-primary p-4"
                    onClick={() => {
                      navigate(`/${language}/account/profile`);
                      setIsOpened(false);
                    }}
                  >
                    <img
                      src={user?.image || DefaultImage}
                      className={cls("h-10 w-10 rounded-md bg-muted me-3 ")}
                      loading="lazy"
                      onError={(e) => {
                        e.target.src = DefaultImage;
                      }}
                    />

                    <div className="flex flex-col items-start gap-1 text-end w-full pe-10">
                      <p className="text-sm line-clamp-1 text-start">
                        {user?.name}
                      </p>

                      <p
                        className=" text-xs text-muted text-end  line-clamp-1 "
                        dir="ltr"
                      >
                        {user?.mobile}
                      </p>
                    </div>
                  </div>

                  <Separator />
                </>
              )}

              <div className=" bg-white text-base z-50 float-start p-4 pt-0 flex flex-col gap-3 items-start justify-start list-none text-start rounded shadow-lg min-w-[10rem]">
                {/* {!isLoggedIn && (
                <Link
                  onClick={() => {
                    setIsOpened(false);
                  }}
                  to={`/${language}/signin`}
                  className="flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70"
                >
                  <UserIcon className="text-primary-400" size={16} />
                  {t("global.loginOrRegister")}
                </Link>
              )} */}

                <span className="text-base font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"></span>
                {isLoggedIn &&
                  (isInfluencer ? (
                    <>
                      {links
                        .filter((route) => {
                          return route.title === t("account.influencer");
                        })?.[0]
                        ?.links.map((link, i) => {
                          const icon = link.icon;

                          return (
                            <div
                              key={`influencer-${i}`}
                              className="flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70 cursor-pointer"
                              onClick={() => {
                                navigate(`/${language}/account/${link.path}`);
                                setIsOpened(false);
                              }}
                            >
                              {icon &&
                                cloneElement(icon, {
                                  className: "text-primary-400",
                                })}
                              {link.text}
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <>
                      <Link
                        onClick={() => {
                          setIsOpened(false);
                        }}
                        to={`/${language}/account/my-orders`}
                        className="flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70"
                      >
                        <OrdersIcon className="text-primary-400" size={16} />
                        {t("global.myOrders")}
                      </Link>
                      <Link
                        onClick={() => {
                          setIsOpened(false);
                        }}
                        to={`/${language}/account/my-address`}
                        className="flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70"
                      >
                        <LocationOn className="text-primary-400" size={16} />
                        {t("global.addresses")}
                      </Link>
                      {!!remoteConfig?.payment_methods_wallet && (
                        <Link
                          onClick={() => {
                            setIsOpened(false);
                          }}
                          to={`/${language}/account/my-wallet`}
                          className="flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70"
                        >
                          <AccountBalanceWallet
                            className="text-primary-400"
                            size={16}
                          />
                          {t("global.wallet")}
                        </Link>
                      )}

                      <Link
                        onClick={() => {
                          setIsOpened(false);
                        }}
                        to={`/${language}/account/profile`}
                        className="flex items-center gap-1 text-base hover:text-primary font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70"
                      >
                        <UserSolidIcon className="text-primary-400" size={16} />
                        {t("global.profile")}
                      </Link>
                    </>
                  ))}
                {isLoggedIn && (
                  <button
                    onClick={async () => {
                      if (isInfluencer) {
                        await influencerLogout();
                      } else {
                        await logout();
                      }

                      navigate(`/${language}/`);
                      setIsOpened(false);
                    }}
                    loading={isLoading || influencerLoading}
                    className="
                    hide-ring-outline
                    flex  ring-transparent  items-center gap-1 text-base hover:text-primary 
                    font-normal w-full whitespace-nowrap bg-transparent text-bluegray-70"
                  >
                    <LogoutIcon className="text-primary-400" size={16} />
                    {t("global.logout")}
                  </button>
                )}
              </div>
            </div>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default UserOptionsDropdown;
