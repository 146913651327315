import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DefaultImage } from "~/old-app/constants";
import { cls } from "~/old-app/utils/helpers/cls";

const CategoriesSplash = ({ onClose, isLoading, categories }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryClick = (category) => {
    if (category?.children?.length > 0) {
      setSelectedCategory(category);
    } else {
      navigate(`/${language}/categories/${category.slug || category.id}`);
      onClose();
    }
  };

  // const formattedCategories = useMemo(() => {
  //   if (!categories?.length) return [];
  //   const cats = [];

  //   categories?.forEach((cat) => {
  //     cats.push({ ...cat, parent: 1 });
  //     cat?.children?.forEach((subCat) => {
  //       cats.push({ ...subCat, parent: 2 });
  //       subCat?.children?.forEach((subSubCat) => {
  //         cats.push({
  //           ...subSubCat,
  //           parent: 3,
  //         });
  //       });
  //     });
  //   });

  //   return cats;
  // }, [categories?.length]);

  return (
    <div className="fixed inset-0 bg-white z-50 flex flex-col p-4 overflow-y-auto top-[100px]">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-xl font-bold">{t("global.selectCategory")}</h2>
        <button
          onClick={onClose}
          className={`focus:outline-none focus:border-none hover:text-primary-500`}
        >
          <svg
            className={`fill-current text-black hover:text-primary-500`}
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 
          9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex flex-col gap-2 overflow-y-auto">
        {!isLoading && selectedCategory?.children?.length > 0 ? (
          <div className="text-center w-full">
            <span
              className="text-xl font-semibold text-primary-500 text-start mb-2 flex"
              onClick={() => {
                navigate(
                  `/${language}/categories/${
                    selectedCategory.slug || selectedCategory.id
                  }`
                );
                onClose();
              }}
            >
              {selectedCategory?.name}
            </span>
            <div className="grid grid-cols-3 gap-4">
              {selectedCategory?.children?.map((category) => (
                <div
                  key={`mobile-cat-${category.id}`}
                  className="flex flex-col gap-1"
                  onClick={() => handleCategoryClick(category)}
                >
                  <img
                    src={
                      category?.cover || selectedCategory?.cover || DefaultImage
                    }
                    loading="lazy"
                    alt={category.name}
                    className="w-full h-auto mx-auto mb-2 object-cover rounded-full"
                    onError={(e) => {
                      e.target.src = DefaultImage;
                      e.target.onerror = null;
                    }}
                  />

                  <span className={cls("text-sm  line-clamp-2 text-center ")}>
                    {category.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-4">
            {categories?.map((category) => (
              <div
                key={`mobile-cat-${category.id}`}
                className="flex flex-col gap-1"
                onClick={() => handleCategoryClick(category)}
              >
                <img
                  src={category.cover || DefaultImage}
                  alt={category.name}
                  loading="lazy"
                  className="w-full h-auto mx-auto mb-2 object-cover rounded-full"
                  onError={(e) => {
                    e.target.src = DefaultImage;
                    e.target.onerror = null;
                  }}
                />

                <span className={cls("text-sm  line-clamp-2 text-center ")}>
                  {category.name}
                </span>
              </div>
            ))}
          </div>
        )}
        {/* <div
              key={`category-header-${category.id}`}
              className={`border rounded-lg p-4 cursor-pointer`}
              onClick={() => handleCategoryClick(category.slug)}
            >
              <img
                src={category.icon}
                alt={category.name}
                loading="lazy"
                className="h-12 mx-auto mb-2"
              />
              <div className="text-center font-semibold">{category.name}</div>
            </div> */}
      </div>
    </div>
  );
};

export default CategoriesSplash;
