// ApiClient.js
import axios from "axios";
import { dispatch } from "~/old-app/redux";
import { actionTypes } from "~/old-app/redux/auth/actions";
import { getUserDataFromCookies } from "~/old-app/utils/helpers/getUser";
import { getLocationFromCookies } from "~/old-app/utils/helpers/getLocation";
import { getEnv } from "~/old-app/utils/helpers/getEnv";
import {
  onCookieChange,
  getCookie,
} from "~/old-app/utils/helpers/cookieManager";
import { CookieEnum } from "~/old-app/constants";

const client = axios.create({
  baseURL: getEnv().BASE_BE_API,
  validateStatus: (status) => {
    if (
      status === 401 ||
      // && !localStorage.getItem("@matjr__loginStatus")
      status === 403
    ) {
      dispatch({
        type: actionTypes.LOGOUT,
      });
      return false;
    }
    if (status >= 200 && status <= 300) return true;
    return false;
  },
});

const updateHeaders = () => {
  // const userData = getUserDataFromCookies();
  // // const token = userData.token || "null";
  // const token = getCookie(CookieEnum.Token) || "null";
  // const district_id = getCookie(CookieEnum.DistrictId) || "null";
  // const address_id = getCookie(CookieEnum.AddressId) || null;
  // const location = getLocationFromCookies();
  // TODO: add country and currency
  // client.defaults.headers.common.Authorization = `Bearer ${token}`;
  // client.defaults.headers.common["district-id"] = district_id;
  // if (address_id) {
  //   client.defaults.headers.common["address-id"] = address_id;
  // } else {
  //   delete client.defaults.headers.common["address-id"];
  // }
  // if (location) {
  //   const { lat, lng } = location;
  //   if (lat) client.defaults.headers.common["latitude"] = lat;
  //   if (lng) client.defaults.headers.common["longitude"] = lng;
  // } else {
  //   delete client.defaults.headers.common["latitude"];
  //   delete client.defaults.headers.common["longitude"];
  // }
};

// Initial setup
updateHeaders();

//TODO: handle lang dynamically
client.defaults.headers.Language = "en";
client.defaults.headers["User-Agent-Type"] = "web";

// Listen for cookie changes
// onCookieChange(({ name }) => {
//   if (
//     [
//       "token",
//       CookieEnum.DistrictId,
//       CookieEnum.AddressId,
//       CookieEnum.Location,
//     ].includes(name)
//   ) {
//     updateHeaders();
//   }
// });

export default client;
