import { useLoaderData } from "@remix-run/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { I18nSupportedLngs } from "~/i18n";
import { useCart, useOfflineCart } from "./api/cart";
import { useCountries } from "./api/country";
import { useService, useServices } from "./api/service";
import { userUserProfile } from "./api/user";
import { SpinnerIcon } from "./assets/icons";
import { CookieEnum } from "./constants";
import { useConfig } from "./hooks/use-config";
import { dispatch } from "./redux";
import { useAuthSlice } from "./redux/auth-slice";
import { ConfigActions, useConfigSlice } from "./redux/config-slice";
import { getCookie, setCookie } from "./utils/helpers/cookieManager";
import CacheBuster from "react-cache-buster";
import { version } from "./../../package.json";
import { getEnv } from "./utils/helpers/getEnv";

function AppProvider({ children }) {
  const { accessToken, isInfluencer, user } = useAuthSlice();
  const { serviceDetails, selectedService } = useConfigSlice();
  const { remoteConfig } = useLoaderData();
  const isProduction = getEnv().NODE_ENV === "production";



  useEffect(() => {
    if (remoteConfig) {
      dispatch(ConfigActions.setRemoteConfig(remoteConfig));
    }
  }, [remoteConfig?.app_name]);

  useService();

  userUserProfile({
    skip: isInfluencer || !accessToken,
  });
  const location = useLocation();
  const pathWithoutLang = location.pathname
    .split("/")
    .filter((segment) => segment && !I18nSupportedLngs.includes(segment))
    .join("/");

  useServices();
  useCountries();
  useCart();
  useOfflineCart();

  const navigate = useNavigate();
  const { app_name, logo } = useConfig();

  useEffect(() => {
    const uuid = getCookie(CookieEnum.OfflineCartKey);

    if (!uuid) {
      const newUuid = v4();
      setCookie(CookieEnum.OfflineCartKey, newUuid);
    }
  }, [user?.default_locale]);

  if (!selectedService || !remoteConfig || !serviceDetails)
    return (
      <div className="h-screen w-full flex flex-col items-center justify-center gap-10">
        <img
          alt={app_name}
          src={logo}
          className="w-[20rem] max-w-6xl"
          loading="lazy"
        />
        <SpinnerIcon
          className="animate-spin text-2xl text-primary-500"
          size={60}
        />
      </div>
    );

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      // loadingComponent={<Loading />}
      metaFileDirectory={"."}
    >
      {children}
    </CacheBuster>
  );
}

export default AppProvider;

// const [selectedService, setSelectedService] = useState(
//   getCookie(CookieEnum.SelectedService)
// );
// const [districtId, setDistrictId] = useState(
//   getCookie(CookieEnum.DistrictId)
// );

// function updateDistrictId(val) {
//   if (districtId != val) {
//     setDistrictId(val);
//   }
// }

// useEffect(() => {
//   client.defaults.headers.Language = language;
//   // TODO: remove these lines
//   dispatch(remoteConfigActions.remoteConfigRequestSuccess(remoteConfig));
//   dispatch(settingsActions.settingsRequestSuccess(remoteConfig?.settings));
// }, []);

// useEffect(() => {
//   if (!isInfluencer && isLoggedIn && !isProfileLoading && !profile) {
//     dispatch(userprofileActions.userprofileRequest());
//   }

//   if (isInfluencer && isLoggedIn && !profile) {
//     dispatch(userprofileActions.influencerProfileRequest());
//   }
// }, [isLoggedIn, isProfileLoading, profile]);

// useEffect(() => {
//   if (!isRemoteConfigLoading) {
//     setCookie(
//       CookieEnum.DefaultLocation,
//       remoteConfig?.force_select_default_locations,
//       { expires: 7 }
//     );
//     if (!selectedService && remoteConfig?.default_service_id) {
//       setCookie(
//         CookieEnum.SelectedService,
//         remoteConfig?.default_service_id,
//         {
//           expires: 7,
//         }
//       );
//       setSelectedService(remoteConfig?.default_service_id);
//     }
//     if (remoteConfig?.force_select_default_locations) {
//       if (isLoggedIn && !isProfileLoading && user) {
//         if (user?.address) {
//           setCookie(CookieEnum.DistrictId, user?.address.district_id, {
//             expires: 7,
//           });
//           updateDistrictId(user?.address.district_id);
//           client.defaults.headers["district-id"] = user?.address.district_id;
//           const selectedLocation = {
//             lat: user?.address.location.latitude,
//             lng: user?.address.location.longitude,
//           };
//           setCookie(CookieEnum.Location, JSON.stringify(selectedLocation), {
//             expires: 7,
//           });
//           const selectedAddress = {
//             name: user?.address.name,
//             region: user?.address.region,
//             country: user?.address.country_id.name,
//             postalCode: user?.address.postal_code,
//             city: user?.address.city_id.name,
//             streetAddress: user?.address.address_details,
//           };
//           setCookie(
//             CookieEnum.selectedAddress,
//             JSON.stringify(selectedAddress),
//             {
//               expires: 7,
//             }
//           );
//           setCookie(CookieEnum.AddressId, user?.address.id);
//         } else {
//           Cookies.remove(CookieEnum.DistrictId);
//         }
//       } else if (!isLoggedIn) {
//         const existingDistrictId = getCookie(CookieEnum.DistrictId);

//         if (!existingDistrictId) {
//           setCookie(
//             CookieEnum.DistrictId,
//             remoteConfig?.default_location.district_id,
//             {
//               expires: 7,
//             }
//           );
//           updateDistrictId(remoteConfig?.default_location.district_id);
//           client.defaults.headers["district-id"] =
//             remoteConfig?.default_location.district_id;
//         }

//         const selectedLocation = {
//           lat: remoteConfig?.default_location.latitude,
//           lng: remoteConfig?.default_location.longitude,
//         };
//         setCookie(CookieEnum.Location, JSON.stringify(selectedLocation), {
//           expires: 7,
//         });
//       }
//     }
//   }
// }, [
//   remoteConfig,
//   isRemoteConfigLoading,
//   user,
//   isProfileLoading,
//   isLoggedIn,
//   selectedService,
// ]);

// useEffect(() => {
//   const handleCookieChange = ({ name, value }) => {
//     if (name === CookieEnum.SelectedService) setSelectedService(value);
//     if (name === CookieEnum.DistrictId) {
//       updateDistrictId(value);
//       // client.defaults.headers["district-id"] = value; // Update header immediately
//     }
//     if (name === CookieEnum.AddressId) {
//       // client.defaults.headers["address-id"] = value; // Update header immediately
//     }
//   };

//   onCookieChange(handleCookieChange);

//   return () => {
//     offCookieChange(handleCookieChange);
//   };
// }, []);

// useEffect(() => {
//   if (districtId) {
//     client.defaults.headers["district-id"] = districtId;
//     dispatch(servicesActions.servicesRequest());
//   }
// }, [districtId, language]);

// useEffect(() => {
//   if (selectedService && districtId) {
//     dispatch(
//       serviceProductsActions.productsRequest({ service_id: selectedService })
//     );
//     dispatch(
//       serviceBranchesActions.branchesRequest({ service_id: selectedService })
//     );
//   }
// }, [language, selectedService, districtId]);

// useEffect(() => {
//   if (!isRemoteConfigLoading && selectedService && isLoggedIn) {
//     dispatch(orderActions.ordersRequest({ service_id: selectedService }));
//   }
// }, [isRemoteConfigLoading, selectedService, isLoggedIn, language]);

// const {
//   i18n: { language },
// } = useTranslation();
// const dispatch = useDispatch();
// const { remoteConfig, isLoading: isRemoteConfigLoading } =

//

// const [districtId, setDistrictId] = useAtom(districtIdAtom)
// const { remoteConfig } = useRouteLoaderData("root");
// const isRemoteConfigLoading = !remoteConfig;
// let {
//   t,
//   i18n: { language },
// } = useTranslation();
// const dispatch = useDispatch();
// const User = useSelector((state) => state.User);
// const { profile, isLoading: isProfileLoading } = useSelector(
//   (state) => state.Userprofile.userprofile
// );
// const [selectedService, setSelectedService] = useState(
//   getCookie("selectedService")
// );

// useEffect(() => {
//   client.defaults.headers.Language = language;

//   dispatch(remoteConfigActions.remoteConfigRequestSuccess(remoteConfig));
//   dispatch(settingsActions.settingsRequestSuccess(remoteConfig.settings));
//   setDistrictId(getCookie("district_id"))

// }, []);

// useEffect(() => {
//   if (isLoggedIn && !isProfileLoading && !profile) {
//     dispatch(userprofileActions.userprofileRequest());
//   }
// }, [isLoggedIn, isProfileLoading, profile]);

// useEffect(() => {
//   if (!isRemoteConfigLoading) {
//     setCookie(
//       "use_default_location",
//       remoteConfig?.force_select_default_locations,
//       { expires: 7 }
//     );
//     if (!selectedService && remoteConfig?.default_service_id) {
//       setCookie("selectedService", remoteConfig?.default_service_id, {
//         expires: 7,
//       });
//       setSelectedService(remoteConfig?.default_service_id);
//     }
//     if (remoteConfig?.force_select_default_locations) {
//       if (isLoggedIn && !isProfileLoading && profile) {
//         if (profile?.address) {

//           setDistrictId(profile?.address.district_id);

//           const selectedLocation = {
//             lat: profile?.address.location.latitude,
//             lng: profile?.address.location.longitude,
//           };
//           setCookie("location", JSON.stringify(selectedLocation), {
//             expires: 7,
//           });
//           const selectedAddress = {
//             name: profile?.address.name,
//             region: profile?.address.region,
//             country: profile?.address.country_id.name,
//             postalCode: profile?.address.postal_code,
//             city: profile?.address.city_id.name,
//             streetAddress: profile?.address.address_details,
//           };
//           setCookie("selectedAddress", JSON.stringify(selectedAddress), {
//             expires: 7,
//           });
//           setCookie("address_id", profile?.address.id);
//         }
//       } else if (!isLoggedIn) {
//         const existingDistrictId = getCookie("district_id");

//         if (!existingDistrictId) {
//           setDistrictId(remoteConfig?.default_location.district_id);
//         }

//         const selectedLocation = {
//           lat: remoteConfig?.default_location.latitude,
//           lng: remoteConfig?.default_location.longitude,
//         };
//         setCookie("location", JSON.stringify(selectedLocation), {
//           expires: 7,
//         });
//       }
//     }
//   }
// }, [
//   remoteConfig,
//   isRemoteConfigLoading,
//   profile,
//   isProfileLoading,
//   isLoggedIn,
//   selectedService,
// ]);

// useEffect(() => {
//   const handleCookieChange = ({ name, value }) => {
//     if (name === "selectedService") setSelectedService(value);

//     if (name === "address_id") {
//       // client.defaults.headers["address-id"] = value; // Update header immediately
//     }
//   };

//   onCookieChange(handleCookieChange);

//   return () => {
//     offCookieChange(handleCookieChange);
//   };
// }, []);

// useEffect(() => {
//   if (districtId) {
//     // client.defaults.headers["district-id"] = districtId;
//     dispatch(servicesActions.servicesRequest());
//   }
// }, [districtId, language]);

// useEffect(() => {
//   if (selectedService && districtId) {
//     dispatch(
//       serviceProductsActions.productsRequest({ service_id: selectedService })
//     );
//     dispatch(
//       serviceBranchesActions.branchesRequest({ service_id: selectedService })
//     );
//   }
// }, [language, selectedService, districtId]);

// useEffect(() => {
//   if (!isRemoteConfigLoading && selectedService && isLoggedIn) {
//     dispatch(orderActions.ordersRequest({ service_id: selectedService }));
//   }
// }, [isRemoteConfigLoading, selectedService, isLoggedIn, language]);

// useEffect(() => {
//   if (!isProfileLoading && profile && isLoggedIn) {
//     profile?.default_locale === "en"
// >>>>>>> hudhud-prod
//       ? dispatch(languageSwitcherActions.changeLanguage("english"))
//       : dispatch(languageSwitcherActions.changeLanguage("arabic"));
//   }
// }, [isLoggedIn, user?.default_locale]);

// return children;
// }

// export default AppProvider;
