import { useLocation } from "@remix-run/react";
import { useTranslation } from "react-i18next";
import { I18nSupportedLngs, languages } from "~/i18n";
import { useUpdateUserSettingsMutation } from "~/old-app/api/user";
import { CookieEnum } from "~/old-app/constants";
import { dispatch } from "~/old-app/redux";
import { useAuthSlice } from "~/old-app/redux/auth-slice";
import { ConfigActions } from "~/old-app/redux/config-slice";
import { setCookie } from "~/old-app/utils/helpers/cookieManager";
import { Select } from "../ui/custom-select";
import { cls } from "~/old-app/utils/helpers/cls";
import { useUpdateInfluencerSettingsMutation } from "~/old-app/api/influencer";

const LangDropdown = ({ className }) => {
  const { accessToken, isInfluencer } = useAuthSlice();
  const isLoggedIn = !!accessToken;
  const [updateSetting, { isLoading }] = useUpdateUserSettingsMutation();
  const [
    updateInfluencerSettings,
    { isLoading: updateInfluencerSettingsLoading },
  ] = useUpdateInfluencerSettingsMutation();
  const {
    t,
    i18n: { language, changeLanguage },
  } = useTranslation();
  const location = useLocation();
  const pathWithoutLang = location.pathname
    .split("/")
    .filter((segment) => segment && !I18nSupportedLngs.includes(segment))
    .join("/");

  const langs = languages.map((lang) => ({
    ...lang,
    name: t(lang.name),
    flag: undefined,
  }));

  const handleLanguageChange = (newLang) => {
    if (newLang === language) return;
    const changeLanguageActions = () => {
      setCookie(CookieEnum.Language, newLang);
      dispatch(ConfigActions.setLanguage(newLang));
      changeLanguage(newLang);

      window.history.pushState(
        {},
        "",
        `/${newLang}/${pathWithoutLang}${location.search}`
      );
      window.location.reload();
    };
    if (isLoggedIn) {
      (isInfluencer
        ? updateInfluencerSettings({
            default_locale: newLang,
          })
        : updateSetting({
            default_locale: newLang,
          })
      )
        .unwrap()
        .then(() => {
          changeLanguageActions();
        });
    } else {
      changeLanguageActions();
    }
  };

  return (
    <Select
      preWord={t("global.language") + "-"}
      className={cls("w-[12rem]", className)}
      onChange={handleLanguageChange}
      options={langs}
      isLoading={isLoading || updateInfluencerSettingsLoading}
      value={language}
      defaultValue={language}
      placeholder={t("global.language")}
      placeholderClassName="pe-10"
    />
  );
};
export default LangDropdown;
