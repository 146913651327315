import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { SearchIcon } from "~/old-app/assets/icons";
import { useDiv } from "~/old-app/hooks/use-div";
import { useParamUpdater } from "~/old-app/hooks/useParamUpdator";
import { Popover, PopoverTrigger } from "../ui/popover";

const SearchForm = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const navigate = useNavigate();
  const { params } = useParamUpdater();
  const [searchText, setSearchText] = useState("");
  const [searchText2, setSearchText2] = useState("");

  // const { products, isFetching } = useProductsWithoutConcat({
  //   text: searchText,
  // });

  const products = [];
  const isFetching = false;
  const [isOpened, setIsOpened] = useState(false);
  const { ref, width } = useDiv();

  const goToSearch = () => {
    const pathname = window.location.pathname;
    const isSamePath = pathname.includes("/search-results");

    if (isSamePath) {
      navigate(`/${language}/search-results?text=${searchText2}&page=1`, {
        state: { text: searchText2 },
        search: `?text=${searchText2}`,
      });
    } else {
      navigate(`/${language}/search-results?text=${searchText2}&page=1`, {
        state: { text: searchText2 },
        search: `?text=${searchText2}`,
      });
    }
  };
  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      goToSearch();
      setIsOpened(false);
    }
  };

  const onSearch = useDebouncedCallback((event) => {
    setSearchText(event.target.value);
    event.preventDefault();
    setIsOpened(true);
  }, 500);

  return (
    <div className="relative w-full my-2  flex flex-row">
      <Popover open={isOpened} onOpenChange={setIsOpened}>
        <PopoverTrigger asChild>
          <div className="group flex relative w-full">
            <input
              ref={ref}
              type="text"
              id="text"
              name="text"
              defaultValue={params?.text}
              placeholder={t("global.search")}
              className="border text-sm pe-10 border-primary-300 text-gray-700 placeholder:text-gray-700 focus:ring-transparent w-full rounded-lg appearance-none disabled:text-gray-700/50 focus-within:border-primary-400 h-10"
              onChange={(e) => {
                onSearch(e);
                setSearchText2(e.target.value);
              }}
              onKeyUp={handleKeyUp}
              onClick={(e) => {
                e.preventDefault();
                setIsOpened(true);
                setTimeout(() => {
                  ref.current?.focus();
                });
              }}
              autoComplete="off"
            />
            <button
              type="button"
              className="rounded-e-md absolute top-0 end-0 p-2  bottom-0 bg-transparent flex items-center group-focus-within:bg-transparent justify-center px-3 h-full border-none text-white rounded-r rtl:rounded-l-lg rtl:rounded-r-none border border-gray-250"
              onClick={goToSearch}
            >
              <SearchIcon size={20} className="text-primary-500" />
            </button>
          </div>
        </PopoverTrigger>

        {/* <PopoverContent
          className="h-auto max-h-[20rem]  bg-main shadow-lg rounded-md bg-white  overflow-y-scroll "
          align="center"
          style={{ width }}
        >
          <div className={cls(" w-full relative flex flex-col")}>
            <div className=" bg-white text-base z-50 float-start p-4 pt-0 flex flex-col gap-3 items-start justify-start list-none text-start rounded shadow-lg min-w-[10rem]">
              {!isFetching && products.length === 0 && (
                <NoData
                  title={t("global.noProductsTitle")}
                  subtitle={t("global.noProductsSubtitle")}
                />
              )}
              {!!isFetching && (
                <div className="flex w-full justify-center items-center py-10">
                  <SpinnerIcon
                    className="animate-spin text-2xl text-primary"
                    size={30}
                  />
                </div>
              )}
              {products.length > 0 && !isFetching && (
                <ul className="flex flex-col gap-2 ">
                  {products.map((product) => (
                    <li key={`product-${product.id}`}>
                      <Link
                        to={`/${language}/products/${
                          product.slug || product.id
                        }`}
                        className="flex items-center gap-2"
                        onClick={() => setIsOpened(false)}
                      >
                        <img
                          src={product.image}
                          alt={product.name}
                          loading="lazy"
                          className="w-10 h-10"
                        />
                        <span>{product.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </PopoverContent> */}
      </Popover>
    </div>
  );
};

export default SearchForm;
