import { WhatsappSupport } from "./whatsapp-support";

export const Maintenance = ({ remoteConfig, dir }) => {
  return (
    <div className="flex items-center justify-center h-screen flex-col container relative">
      <img
        alt={remoteConfig?.app_name}
        src={remoteConfig?.logo}
        className="max-h-20 min-h-20 min-w-20 object-cover mb-3"
      />

      <img
        src="/defaults/maintenance.svg"
        alt="maintenance"
        className="w-full h-96 object-contain"
        loading="lazy"
      />
      <h1 className="text-xl md:text-3xl font-bold mt-6 md:mt-14 text-center">
        {dir === "rtl"
          ? remoteConfig?.maintenance_mode?.message_ar
          : remoteConfig?.maintenance_mode?.message_en}
      </h1>
      <p className="mt-2">{remoteConfig?.maintenance_message}</p>

      <WhatsappSupport className="bottom-4" />
    </div>
  );
};
