import { useNavigation } from "@remix-run/react";
import { useEffect, useState } from "react";
import Footer from "~/old-app/components/Footers/Footer";
import Navbar from "~/old-app/components/Navbars/Navbar";
import { ClientOnly } from "~/old-app/utils/helpers/clientOnly";
import { CookieModal } from "../components/cookie-modal";
import { GoToTop } from "../components/go-to-top";
import { WhatsappSupport } from "../components/whatsapp-support";
import { getCookie } from "../utils/helpers/cookieManager";

const RenderNavbars = () => {
  return (
    <ClientOnly>
      <Navbar />
    </ClientOnly>
  );
};

{
  /* {!isMobile && remoteConfig?.enable_services_page && !isRootPath && (
        <ServicesNavbar />
      )} */
}

const PageLoading = () => {
  const { state } = useNavigation();

  return state === "loading" ? (
    <div
      className="w-full h-full fixed z-[10000000] flex justify-center items-center"
      style={{
        background: "#bababa57",
      }}
    >
      <div
        className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
        style={{ color: "#f6933b" }}
      ></div>
    </div>
  ) : (
    <></>
  );
};

const MainLayout = ({ children }) => {
  const [isAccept, setIsAccept] = useState(false);

  useEffect(() => {
    setIsAccept(getCookie("CookieConsent") === "true");
  }, []);

  return (
    <>
      <PageLoading />
      <RenderNavbars />
      {/* <main className="">{children}</main> */}
      <main className="md:min-h-screen">{children}</main>
      <Footer />
      {isAccept && <WhatsappSupport />}
      <CookieModal onAccept={setIsAccept} />
      <GoToTop />
    </>
  );
};

export default MainLayout;
